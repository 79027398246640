import React from "react";
import { graphql, StaticQuery } from "gatsby";

export default ({ name, label, url, ...props }) => {
    return (
        <StaticQuery
            query={query}
            render={(data) => {
                let node = data.logos.edges.find((edge) => edge.node.name === name);

                const title = label;
                const alt = `Logo ${label}`;

                if (!node) return <></>;

                let image = (
                    <img src={node.node.publicURL} alt={alt} title={title} className="mx-auto" />
                );

                if (url) {
                    image = (
                        <a href={url} rel="noopener noreferrer" target="_blank">
                            {image}
                        </a>
                    );
                }

                return (
                    <div
                        className="w-1/3 text-center px-md py-lg md:w-1/4 md:py-xl xl:py-2xl"
                        {...props}
                    >
                        <div className="object-contain mx-auto max-w-xxs">{image}</div>
                    </div>
                );
            }}
        />
    );
};

const query = graphql`
    query LogosQuery {
        logos: allFile(filter: { relativePath: { glob: "logo-*" } }) {
            edges {
                node {
                    name
                    publicURL
                    relativePath
                }
            }
        }
    }
`;
