import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Column from "~/column";
import Container from "~/container";
import Intro from "~/intro";
import LargeText from "~/large-text";
import Layout from "~/layout";
import Logos from "~/logos";
import SEO from "~/seo";

const IndexPage = (props) => {
    return (
        <StaticQuery
            query={query}
            render={(data) => {
                return (
                    <Layout>
                        <Intro
                            title={(data.site && data.site.title) || "Büro Schriever"}
                            image={
                                <GatsbyImage
                                    image={getImage(data.heroImage)}
                                    alt="Unser Büro in Halver"
                                />
                            }
                        >
                            Wahre Werte sind unvergänglich. Das ist eine der Über­zeug­ungen, denen
                            wir uns verpflichtet fühlen. Mit guter Beratung und Betreuung.
                        </Intro>
                        <Container spacing={true}>
                            <Column className="lg:w-3/4 lg:mx-auto xl:w-2/3">
                                <h2>Geradliniges Design für geradlinige Menschen</h2>

                                <LargeText>
                                    <p>
                                        Wir entwickeln vor allem integrierte Gesamtlösungen. Möchten
                                        Sie einen klassischen Bürotrakt repräsentativ einrichten?
                                        Soll Ihre Fertigungsstätte mit angeschlossener Verwaltung
                                        aufgewertet werden ohne Nachteile für die Funktionalität?
                                        Oder planen Sie gar einen Neubau, der auch noch in vielen
                                        Jahren in frischer, innen­architektonischer Faszination
                                        erstrahlen soll?
                                    </p>

                                    <p>
                                        Grundvoraussetzung ist in jedem Fall eine intensive
                                        Objektplanung, die keinen Aspekt unberücksichtigt lässt: Mit
                                        diesen integrierten Gesamtlösungen und mit planerischer
                                        Sorgfalt haben wir uns einen Namen gemacht. Einen Namen, dem
                                        Sie vertrauen können.
                                    </p>
                                </LargeText>
                            </Column>
                        </Container>
                        <Container spacing={true}>
                            <Column>
                                <Logos />
                            </Column>
                        </Container>
                        {/*TODO: <PromoProducts /> */}
                    </Layout>
                );
            }}
        />
    );
};

export const Head = () => <SEO />;

export default IndexPage;

const query = graphql`
    query HomePageQuery {
        site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
            title
        }
        heroImage: file(relativePath: { eq: "hero-home.jpg" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`;
