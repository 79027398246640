import React from "react";
import Item from "~/logos-item";

export default ({ ...props }) => {
    return (
        <div
            className="flex flex-wrap items-center justify-start rounded p-lg -m-md dark:bg-white"
            {...props}
        >
            <Item name="logo-arper" label="arper" url="https://www.arper.com/" />
            <Item name="logo-nimbus" label="Nimbus" url="https://nimbus-lighting.com/" />
            <Item name="logo-thonet" label="Thonet" url="https://www.thonet.de/" />
            <Item name="logo-usm" label="USM" url="https://www.usm.com/" />
            <Item name="logo-vitra" label="Vitra" url="https://www.vitra.com/" />
            <Item name="logo-wilkhahn" label="Wilkhahn" url="https://www.wilkhahn.com/" />
        </div>
    );
};
